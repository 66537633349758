import { BaseStepIdEnum, StepConfig, StepTypeEnum } from 'shared-domain';
import { CompanyDetails } from '../app/components/company-details-v2';

export const stepsConfig: StepConfig[] = [
  {
    id: 'disclaimer',
    type: StepTypeEnum.text,
  },
  {
    id: BaseStepIdEnum.company_search,
  },
  {
    id: BaseStepIdEnum.company_list,
  },
  {
    id: BaseStepIdEnum.company_edit,
  },
  {
    id: 'company_details',
    type: StepTypeEnum.custom_component,
    component: CompanyDetails,
  },
  {
    id: BaseStepIdEnum.individuals_list,
    config: { hasApplicant: false },
  },
];
