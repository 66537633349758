import { useMemo } from 'react';
import { Box, Button, Input, VStack } from '@chakra-ui/react';
import { Resolver, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { GroupController, Select, SelectOption } from 'frontend-components';
import { useStepId, useStore, useSubmitCompanyForm } from 'frontend-common';
import { elfCodeMapping } from '../../assets/elf_code_mapping';
import { InferType } from 'yup';

const trade_registry_location = 'trade_registry_location';

const validationSchema = Yup.object({
  subdivision_country: Yup.string().optional(),
  legal_form: Yup.string().required().label('Legal form'),
  [trade_registry_location]: Yup.string()
    .required()
    .label('Trade registry location'),
});

type CompanyDetailFormValues = InferType<typeof validationSchema>;

export const CompanyDetails = () => {
  const stepId = useStepId();

  const { t } = useTranslation();
  const { submitCompanyForm } = useSubmitCompanyForm();
  const { company, metadata } = useStore();

  const defaultValues = useMemo(() => {
    return {
      subdivision_country: metadata['subdivision_country']?.toString() || '',
      legal_form: company?.legal_form || '',
      [trade_registry_location]:
        metadata?.[trade_registry_location]?.toString() || '',
    } as CompanyDetailFormValues;
  }, [company, metadata]);

  const methods = useForm<CompanyDetailFormValues>({
    //const methods = useForm<any>({
    mode: 'all',
    criteriaMode: 'all',
    // @TODO - OPS-9 - Replace Yup by Zod
    resolver: yupResolver(
      validationSchema,
    ) as Resolver<CompanyDetailFormValues>,
    context: { company_country: company?.country },
    defaultValues,
  });

  const {
    handleSubmit,
    control,
    setValue,
    resetField,
    watch,
    formState: { isValid, isSubmitting },
  } = methods;
  const onSubmit: SubmitHandler<CompanyDetailFormValues> = async (formData) => {
    // const onSubmit: SubmitHandler<any> = async (formData) => {
    if (company) {
      submitCompanyForm({
        companyData: {
          ...company,
          legal_form: formData.legal_form,
        },
        caseMetadata: {
          ...metadata,
          trade_registry_location: formData.trade_registry_location,
          subdivision_country: formData.subdivision_country ?? null,
        },
      });
    }
  };

  const hasSubdivision = () => {
    return company?.country
      ? elfCodeMapping[company.country].find((s) => s.subdivision_code !== null)
      : false;
  };

  const subdivisionOptions = () => {
    return elfCodeMapping[company?.country as string]
      .filter(
        (s) => s.subdivision_code !== null && s.subdivision_label !== null,
      )
      .map((s) => {
        return {
          value: s.subdivision_code as string,
          label: s.subdivision_label as string,
        };
      });
  };

  const legalFormOptions = (subdivision: string | null) => {
    const subdivisions = elfCodeMapping[company?.country as string].filter(
      (s) => s.subdivision_code === subdivision || s.subdivision_code === null,
    );
    const options: SelectOption[] = [];
    subdivisions.forEach((s) => {
      s.legal_forms.forEach((lf) => {
        options.push({
          value: `${lf.code} | ${lf.label}`,
          label: lf.label,
        });
      });
    });

    // Add other option
    options.push({
      value: '8888 | Other',
      label: 'Other',
    });
    // Add not application option
    options.push({
      value: '9999 | Not applicable',
      label: 'Not applicable',
    });

    options.sort((a, b) => a.label.localeCompare(b.label));

    return options;
  };

  const subdivisionCountry = watch('subdivision_country');

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <VStack spacing="6" alignItems="start">
        {hasSubdivision() && (
          <GroupController
            name="subdivision_country"
            label="Jurisdiction"
            isRequired={true}
            control={control}
            render={(f) => (
              <Select
                stepId={stepId}
                name="subdivision_country"
                onChange={(value: string) => {
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  setValue('subdivision_country', value ?? '', {
                    shouldDirty: true,
                    shouldValidate: true,
                  });
                  setValue('legal_form', '');
                }}
                options={subdivisionOptions()}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                defaultValue={f.value}
                isMulti={false}
                isTranslatableOptions={false}
              />
            )}
          />
        )}

        <GroupController
          name="legal_form"
          label={t(`steps.${stepId}.legal_form.label`)}
          isRequired={true}
          control={control}
          render={(f) => (
            <Select
              stepId={stepId}
              name="legal_form"
              onChange={(value: string) => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                resetField(trade_registry_location);
                setValue('legal_form', value ?? '', {
                  shouldDirty: true,
                  shouldValidate: true,
                });
              }}
              options={legalFormOptions(subdivisionCountry ?? null)}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              defaultValue={f.value ?? ''}
              isMulti={false}
              isTranslatableOptions={false}
            />
          )}
        />

        <GroupController
          name={trade_registry_location}
          label={t(`steps.${stepId}.${trade_registry_location}.label`)}
          helper={t(`steps.${stepId}.${trade_registry_location}.helper`)}
          isRequired={true}
          control={control}
          render={(f) => {
            return <Input type="text" maxW="400px" {...f} />;
          }}
        />

        <Box>
          <Button
            variant="next"
            isLoading={isSubmitting}
            isDisabled={!isValid}
            type="submit"
          >
            {t('domain.form.next')}
          </Button>
        </Box>
      </VStack>
    </form>
  );
};
